module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://economizecloud.wpengine.com/graphql","debug":{"timeBuildSteps":true,"preview":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false,"allow401Images":false},"develop":{"hardCacheMediaFiles":false,"nodeUpdateInterval":5000,"hardCacheData":false},"schema":{"requestConcurrency":5,"queryDepth":5,"perPage":100,"timeout":36000000,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"type":{"__all":{"limit":5000},"Post":{"limit":5000},"PostFormat":{"limit":null},"Comment":{"limit":null},"Menu":{"limit":null},"MenuItem":{"limit":null},"User":{"limit":null},"UserRole":{"limit":null},"Tag":{"limit":null},"Slide":{"limit":null},"MediaItem":{"localFile":{"excludeByMimeTypes":["video/mp4"],"maxFileSizeBytes":10485760,"requestConcurrency":5},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6269bc6025bcc09ccbacb3b0f8044fe9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://blog.economize.cloud/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
